.crosslink {
    width: 300px;
    height: 150px;
    position: fixed;
    z-index: 10000;
    top: -65px;
    right: -114px;
    transform: rotateZ(30deg);
    -moz-transform: rotateZ(30deg);
    -webkit-transform: rotateZ(30deg);
    -o-transform: rotateZ(30deg);
    -ms-transform: rotateZ(30deg);
    // opacity: 0.6;
    background: rgba($clr-orange, 0.8);
    color: white;
    text-align: center;
    font-size: 1.6rem;

    transition: opacity 0.4s ease-in-out;

    &:hover {
        opacity: 1;
    }

    &__anchor {
        position: absolute;
        bottom: 0.9rem;
        border: 0;
        left: 0;
        right: 0;
        color: white;
        &:hover {
            color: #eee;
        }
    }
    .topic & {
        opacity: 1;
        left: -120px;
        top: -72px;
        right: auto;
        transform: rotateZ(-30deg);
        -moz-transform: rotateZ(-30deg);
        -webkit-transform: rotateZ(-30deg);
        -o-transform: rotateZ(-30deg);
        -ms-transform: rotateZ(-30deg);


        @include media('<=1100px') {
            width: 300px;
            height: 150px;
            top: -72px;
            right: -120px;
        }
        @include media('<tablet') {
            top: -90px;
            left: -130px;
        }
    }


    @include media('<=1100px') {
        width: 150px;
        top: 45px;
        right: -10px;
        height: 4.8rem + 2 * 0.9rem;
        transform: rotateZ(-30deg);
        -moz-transform: rotateZ(-30deg);
        -webkit-transform: rotateZ(-30deg);
        -o-transform: rotateZ(-30deg);
        -ms-transform: rotateZ(-30deg);
    }

    @include media('<tablet') {
        height: 150px;
        width: 300px;
        top: -95px;
        right: auto;
        left: -117px;
        font-size: 1.4rem;
        line-height: 1.4;
        opacity: 1;
        transform: rotateZ(-20deg);
        -moz-transform: rotateZ(-20deg);
        -webkit-transform: rotateZ(-20deg);
        -o-transform: rotateZ(-20deg);
        -ms-transform: rotateZ(-20deg);
        &__anchor {
            bottom: 0.2rem;
        }
    }

}