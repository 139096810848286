footer {
    background-color: $clr-primary;
    background-color: rgba(darken($clr-primary, 10%), 0.9);
    width: 100%;
}

.footer__content {
    width: 90%;
    max-width: 96.0rem;
    margin: 0 auto;
    @include media("<tablet"){
        display: table;
    }
}

.footer__logos {
    float: right;
    @include media("<tablet"){
        float: none;
    }}