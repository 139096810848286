html {

}

body {
    background-color: $clr-primary;
    // background-color: #53BEB5; // background color for testing semi transparency
}

//clearfix for floating elements -> http://css-tricks.com/all-about-floats/
.clearfix:after {
  content: " ";
  visibility: hidden;
  display: block;
  height: 0px;
  clear: both;
  font-size: 0;
}

/* apply a natural box layout model to all elements, but allowing components to change */
html {
    box-sizing: border-box;
}
*, *:before, *:after {
    box-sizing: inherit;
}


// The element at the top of the page right after the fixed navigation bar MUST have sufficient top margin or else it will be covered by the bar

.content {
    max-width: $width-content; //960px
    width: 90%;
    margin: 0 auto;
    padding-top: 5 * 2.4rem;
    height: 100%;
    @include media ("<tablet"){
        padding-top: 2.4rem;
    }
}

.button {
    border: 0.4rem solid #000;
    background-color: $clr-primary;     // fallback for browsers without rgba
    background-color: rgba($clr-primary, 0.5);
    display: inline-block;
    font-size: 2.4rem;
    text-align: center;
    min-height: 6.4rem; // 72px - 8 px border
    min-width: 8 * 2.4rem;
    line-height: 6.4rem;
    padding: 0 2.4rem 0 2.4rem;
    font-family: $font-family-base-bold;
    font-weight: 700;
    color: #000;
    text-decoration: none;
    &:hover {
        color: $clr-orange;
        background-color: darken( $clr-primary, 5% )
    }
    @include media("<=410px"){
        display: block;
    }
}

.v-rhythm-adjust {
    margin-top: 2.4rem;
}

// different distance to previous elements
.dist-to-prev-base      { margin-top: 2.4rem; }// 24px = 1 unit of baseline grid
.dist-to-prev-double    { margin-top: 2 * 2.4rem; } // 48px = 1 unit of baseline grid
.dist-to-prev-triple    { margin-top: 3 * 2.4rem; } // 48px = 1 unit of baseline grid
.dist-to-prev-quad      { margin-top: 4 * 2.4rem; } // 48px = 1 unit of baseline grid

// distance to following element
.dist-to-next-base      { margin-bottom: 2.4rem; }// 24px = 1 unit of baseline grid
.dist-to-next-double    { margin-bottom: 2 * 2.4rem; } // 48px = 1 unit of baseline grid
.dist-to-next-triple    { margin-bottom: 3 * 2.4rem; } // 48px = 1 unit of baseline grid
.dist-to-next-quad      { margin-bottom: 4 * 2.4rem; } // 48px = 1 unit of baseline grid



.big-15 {
    float: right;
    @include media("<tablet") {
        float: none;
    }
}

.big-15-streaming {
    margin-top: 20rem;
    @include media('<=1100px') {
        margin-top: 0rem;
        float: none;
    }
    @include media("<tablet") {
        margin-top: 0rem;
        float: none;
    }
}

.streaming {
    margin-top: 3rem;
    &__link {
        border: none;
        .streaming__img {
            max-width: 600px;
            @include media('<=1100px') {
            }
            @include media("<tablet") {
                width: 100%;
            }
        }
    }
}

.eventbrite-wrap {
    width: 100%;
    text-align: left;
}

.panel_head2 {
    background-color: $clr-primary !important;
}

figure {  // = portrait container
    width: 25.0rem;
    float: left;
    height: 17 * 2.4rem;
    margin-right: 2.4rem;
}

.portrait-gif {
    margin-bottom: 0.8rem;
}



.address-block {
    float: left;
    padding-right: 2 * 2.4rem;
    line-height: 1.3;
    // margin-top: 1.4rem;     // to adapt to baseline grid
    padding-bottom: 1.3rem;     // to adapt to baseline grid
    @include media("<tablet"){
        padding-right: 0;
    }
}

.portrait-gif {
    background-color: $clr-orange-gif;
}

#myCanvas {     // = worm cage
    z-index: -100;
    position: fixed;
    width: 100%;
    height: 0px;    // will be set via javascript
}

