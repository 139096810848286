html, body {
    font-family: $font-family-base;
    text-transform: none;
    font-size: $font-size-base;    //10px
    line-height: 2.4rem; //24px = baseline grid

    // ligatures and kerning
    -webkit-font-kerning:   normal;
    -moz-font-kerning:      normal;
    font-kerning:           normal;
    -webkit-font-feature-settings:      "liga";
    -moz-font-feature-settings:         "liga", "kern";
    font-feature-settings:              "liga", "kern";

}

.content, nav, footer {
    font-size: 1.8rem;
}

// http://thesassway.com/advanced/modular-css-typography
// https://drewish.com/tools/vertical-rhythm/
// define classes which can then be applied to the elements (modularity!)
.h1, .h2, .h3, .h4, .h5, .h6 {
    max-width: 30.0rem;
    font-family: $font-family-base-medium;
    font-weight: 700;
    color: $clr-orange;
}

.h1 {
    font-size: 4.0rem;
    line-height: 5.4rem;        // lineheight + margin-top + margin-bottom must be a multiple of 24px (baseline grid)
    margin-top: 2.2rem;
    margin-bottom: 2.0rem;
}

.h2, .h3 {
    font-size: 2.4rem;
    line-height: 3.2rem;
    margin-top: 2.4rem;
    margin-bottom: 1.6rem;
}
.h4 {
    font-size: 2.0rem;
    line-height: 1.4em;
    margin-top: 1.8rem;
    margin-bottom: 1.64em;
}


// .h4 { font-size: 110%; }
// .h5 { font-size: 100%; }

// if necessary to mark text
.quiet { color: $clr-grey-shade; }
// .loud  { color: $loud-color; }

.italic { font-style: italic; }
.bold   { font-weight: 700; }



i, em { @extend .italic; }
b, strong { @extend .bold; }

h1, .h1 { @extend .h1; }
h2, .h2 { @extend .h2; }
h3, .h3 { @extend .h3; }
h4, .h4 { @extend .h4; }



a {
    color: #000;
    text-decoration: none;
    border-bottom: 0.2rem solid #000;
    &:hover {
        color: $clr-orange;
        border-color: $clr-orange;
    }
}

p {
    // hyphenation
    -webkit-hyphens:    auto;
    -moz-hyphens:       auto;
    -ms-hyphens:       auto;
    hyphens:       auto;

    font-size: 1.8rem; //18px
    line-height: 2.4rem; // 1em = $base-font-size, because html/body font-size = $base-font-size -> 1.3333 = 24px
    margin-top: 2.4rem;
    margin-bottom: 2.4rem;
    // http://webtypography.net/2.1.2 -> 33em for article with – in german 40em!
    // our site has a smaller paragraph width
    max-width: 30em;
}

.weight-book { font-family: $font-family-base; }
.weight-medium { font-family: $font-family-base-medium; font-weight: 700; }
.weight-bold { font-family: $font-family-base-bold; font-weight: 700; }
.orange { color: $clr-orange; }
// .line-adjust {
//     line-height: 1.3;
//     margin-bottom: 3.4rem;
//     margin-top: 0;
//     display: inline-block;
// }

.size-14 { font-size: 1.4rem; }
.size-24 { font-size: 2.4rem; }
.size-40 { font-size: 4.0rem; margin-top: 2.2rem; margin-bottom: 2.0rem; line-height: 5.4rem; }
.size-54 { font-size: 5.4rem; margin-top: 3.0rem; margin-bottom: 3.0rem; line-height: 6.0rem; } // 54px, becuase line-height 1.2 -> margin bottom 0.8


.big-15__nr {
    font-size: 30.0rem;
    line-height: 25.0rem; // only visually adapted
    margin-bottom: 1.4rem;
    color: $clr-orange;
    display: block;
    @include media("<=1100px") {
        display: none;
    }
}

.big-15__date {
    padding-left: 5.4rem;
    margin-top: 2.0rem;
    font-size: 2.4rem;
        @include media("<=1100px") {
            float: none;
            padding-left: 0;
        }
    @include media("<tablet"){
        padding-left: 0;
    }
}

.h1--unlimited {
    max-width: 100%;
    margin-left: -0.3rem;
    @include media ("<tablet"){
        margin-top: 0.6rem;
    }

    @include media ("<500px"){
        margin-left: -0.1rem;
        font-size: 3.8rem;
    }
}

.vertical-text {
    display: inline-block;
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);

    /* also accepts left, right, top, bottom coordinates; not required, but a good idea for styling */
    -webkit-transform-origin: 48% 70%;
    -moz-transform-origin: 48% 70%;
    -ms-transform-origin: 48% 70%;
    -o-transform-origin: 48% 70%;
    transform-origin: 48% 70%;
}

.intro__rem-days {
    @include media("<500px"){
        display: none;
    }
}

.intro__button-wrap .button {
    margin-right: 0.5em;
}

.section__title {
    color: $clr-orange;
    position: relative;
    // margin-top: 2.4rem;
    background-color: $clr-primary;
    padding: 0 2.4rem 0 2.4rem;
    margin-left: 7.2rem;
    display: inline-block;
    @include media("<500px"){
        padding-left: 0;
        margin-left: 0;
    }
    @include media("<big-tablet"){
        margin-left: 2.4rem;
        margin-right: 2.4rem;
        padding: 0 0.5rem;
    }
    @include media ("<370px"){
        font-size: 3.0rem;
    }
    .text-inline-bg {
        box-shadow: none;
    }
}

.section__subtitle {
    max-width: 30.0rem; // same as paragraph width
    margin-top: 2.2rem;
    margin-bottom: 2.4rem;
    padding-bottom: 0.2rem;
    color: #000;
    &--secondary {
        max-width: 50rem;
    }
}


.section__subtitle--marg-adjust2 {   // line-height 1.3 and 2 lines -> adjust margin bottom
    margin-top: 2.2rem;
    margin-bottom: 1.0rem;
    padding-bottom: 1.0rem;
}

.section__subtitle--marg-adjust4 {   // line-height 1.3 and 2 lines -> adjust margin bottom
    margin-top: 2.2rem;
    margin-bottom: 1.0rem;
    padding-bottom: 2.0rem;
}

.portrait__fct {
    display: inline-block;
    margin-top: 1.2rem
}

.text-inline-bg {
    background-color: $clr-primary;     // fallback for browsers without rgba
    background-color: rgba($clr-primary, 0.7);
    padding-top: 0.1rem;
    box-shadow: 0.5rem 0 0 rgba($clr-primary, 0.7), -0.5rem 0 0 rgba($clr-primary, 0.7);
}

h2 .text-inline-bg, h3 .text-inline-bg {
    padding: 0.1rem;
}