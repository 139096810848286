$navHeight: 5.0rem !default;

.fixed-nav-bar {
    font-family: $font-family-base-medium;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9999;
    width: 100%;
    height: $navHeight;
    background-color: $clr-primary;     // fallback for browsers without rgba
    background-color: rgba($clr-primary, 0.9);
    font-size: 1.8rem;
    color: #000;
    line-height: 2.5rem;
}

.fixed-nav-bar li, .fixed-nav-bar a {
    height: $navHeight;
    line-height: $navHeight;
}

.menu {
    width: 90%;
    max-width: 96.0rem;
    margin: 0 auto;
    @include media("<tablet") {
        position: relative;
        width: 100%;
    }
}


.menu a:hover, .menu a:target {
    color: $clr-orange;
}

.menu__items {
    display: inline-block;
    width: 100%;
    @include media("<tablet") {
        display: none;
        width: 100%;
        padding-top: $navHeight;
        background-color: $clr-primary;
        background-color: rgba($clr-primary, 0.9);
        background-color: rgba(darken($clr-primary, 10%), 0.9);
    }
}

.menu__items li {
    display: inline-block;
    margin-right: 2.5%;
    @include media("<tablet") {
        display: block;
        text-align: center;
        margin: 0;
        border-top: 0.1rem solid $clr-grey-shade;
        height: $navHeight;
        &:last-of-type {
            border-bottom: 0.1rem solid $clr-grey-shade;
        }
    }

}


.topic .menu__items {
    text-align: right;
}

.menu__separator {
    margin: 0 0.3rem;
}

.menu__links {
    text-decoration: none;
    padding-bottom: 0.5rem;
    border-bottom: 0.2rem #000 solid;
    color: #000;

    &--language.is-active,
    &:hover {
        // color: $clr-orange;
        border-bottom: 0.2rem $clr-orange solid;
    }
}

.show, .hide {
    display: none;
    padding-left: 1.5rem;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center left;
    color: #000;
    @include media("<tablet") {
        position: absolute;
        top: 0;
        right: 1.5rem;
    }
}

.show {
    @include media("<tablet") {
        display: inline-block;
    }
}

.hide {
    @include media("<tablet") {
        display: none;
    }
}

#menu:target .show {
    @include media("<tablet") {
        display: none;
    }
}

#menu:target .hide {
    @include media("<tablet") {
        color: $clr-orange;
    }
}

#menu:target .hide, #menu:target .menu__items {
    @include media("<tablet") {
        display: inline-block;
    }
}

.fixed-nav-bar .menu_btn, .menu_btn {
    height: $navHeight - 1.0rem;
    padding-left: 0;
}

.active {
    border-bottom: 0.2rem $clr-orange solid;
}

