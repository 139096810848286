.post {
	max-width: 100%;

	&__title {
		max-width: 600px;
		font-weight: bold;
	}
	&__text {
		display: inline-block;
		max-width: 600px;
		padding-right: 2.5rem;
		padding-bottom: 6rem;
	}
	&__img {
		max-width: 300px;
		max-height: 300px;
		border: none;
		margin-top: -4.5rem;
		margin-bottom: 4rem;
		display: inline-block;
	}
	&__imglink {
		border: none;
		vertical-align: top;
	}
	&__relatedlink > * {
    	vertical-align: top;
    }
}

.topic {
	&__text {
		max-width: 100%;
	}
	&__subtitle {
		max-width: 100%;
	}
}