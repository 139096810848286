// var definitions
$width-content:         96.0rem;     // 960px


// media query break points
// $big-tablet-width:      1024px;
// $tablet-width:          768px;
// $phone-width:           420px;

$breakpoints: (phone: 320px, phablet: 567px, tablet: 868px, big-tablet: 1024px, desktop: 1400px);


// colors
$clr-primary:           #EEEEEE;
$clr-grey-shade:        #979797;
$clr-grey:              #D8D8D8;
$clr-orange:            #FF4D11;
$clr-link:              #FF4D11;
$clr-orange-gif:        #D18446;
$clr-link-hover:        saturate($clr-link, 20%);

$font-family-base:          "Circular-Book", "Circular", "europa", "Futura", sans-serif;
$font-family-base-bold:     "Circular-Bold", "Circular", "europa", "Futura", sans-serif;
$font-family-base-medium:   "Circular-Medium", "Circular", "europa", "Futura", sans-serif;
$font-size-base:            62.5%;      // 16px (default) * 0.625 = 10px        _> base 10 to calc easier
// $base-font-weight: 300;
// $base-font-line-height: 24px;


$spacer-base: 24px;

// mixins


// import compass-mixins to use compass with libsass
@import "../bower_components/compass-mixins/lib/compass";
@import "../bower_components/_normalize.scss/normalize";
@import "../bower_components/include-media/dist/include-media";

// import stylesheets
@import "reset";
@import "typography";
@import "tables";
@import "navigation";
@import "general";
@import "footer";
@import "footnote";
@import "schedule";
@import "post";
@import "section";
@import "crosslink";
@import "sponsorgroup";
@import "info";
@import "awardceremony";
