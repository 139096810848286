.section {
    min-height: 19*2.4rem;
    padding-bottom: 3*2.4rem;
    @include media("<tablet") {
        min-height: 10*2.4rem;
    }

    &__region {
        &--sponsors {
            @include clearfix();
            margin-bottom: $spacer-base * 2;
        }
        &--head-sponsors {
            @include clearfix();
            margin-top: $spacer-base * 1;
            .sponsorgroup__sponsor {
                width: 20%;
                padding: $spacer-base * 0.5;
                margin-right: $spacer-base;
                @include media("<=phablet") {
                    width: 45%;
                    margin-right: 5%;
                }
                &-logo {
                    width: 100%;
                }
                &--surface {
                    margin-top: $spacer-base * 0.25;
                    .sponsorgroup__sponsor-logo {
                        width: 100%;
                    }
                }
            }
        }
        &--head-sponsors--streaming {
            .sponsorgroup__sponsor {
                width: 20%;
                @include media('<=1100px') {
                    width: 25%;
                }
                @include media("<=phablet") {
                    width: 45%;
                }
            }
        }
    }

	&--intro {
		min-height: 15rem;
		margin-top: 8rem;
	}

    &--intro-topic {
        padding-top: 2*2.4rem; // to keep vertical rhythm
        margin-top: 0;
        @include media("<tablet") {
            padding-top: 4*2.4rem;
        }
    }

    &--topic {
        margin-bottom: 0;
        padding-bottom: 6rem;
        min-height: 0;
    }

    &--sponsors {
        .section__subtitle--lead {
            max-width: none;
            text-align: center;
        }
    }

    &__postdivider {
    	background-color: $clr-grey;
        height: 0.2rem; // same as underline in navigation
        border: none;
        margin-bottom: 3rem;
    }

    &-divider {
        background-color: $clr-orange;
        height: 0.2rem; // same as underline in navigation
        border: none;
        margin: 0 0 -0.2rem 0; // to adjust the shift in the following layout
        position: relative;
        top: 4.7rem;
    }
}
