.footnote {
    margin-top: 1rem;
    margin-bottom: 0;
    // &__link {
        //
    // }
    .button ~ & {
        margin-top: 3rem;
    }
}
