.sponsorgroup {
    @include clearfix();
    padding-top: $spacer-base;
    width: 100%;
    float: left;


    &__title {
        @include highlightItem($clr-primary);
        position: relative;
        max-width: none;
        display: inline-block;
        margin-top: 0;
        line-height: 1em;
        margin-bottom: $spacer-base / 2;
        // margin-left: $spacer-base;
        margin-left: $spacer-base;
        // text-align: center;
        // margin: 0 auto $spacer-base;
        color: darken($clr-grey-shade, 16%);


        &:before {
            content: '';
            display: block;
            position: absolute;
            left: -$spacer-base;
            bottom: ($spacer-base / 3);
            z-index: -1;
            width: $width-content;
            border-top: 2px solid darken($clr-grey-shade, 16%);
            opacity: 0.1;
        }
    }

    &__list {
        @include clearfix();
        background: rgba($clr-primary, 0.8);
        padding-bottom: $spacer-base;
    }


    &__sponsor {
        float: left;
        width: 25%;
        padding: $spacer-base;
        &-logo {
            display: block;
            width: 80%;
            margin: 0 auto;
        }
        &-name {
            display: none;
        }
        &-link {
            display: block;
            width: 100%;
            border-bottom: 0;
        }
    }

    @include media("<phablet") {
        &__sponsor {
            width: 50%;
            &-logo {
                width: 90%;
            }
        }
    }
    @include media("<=phone") {
        &__sponsor {
            width: 100%;
        }
    }


    /**
     * Customisations
     */

    &--gold {
        .sponsorgroup {
            &__title {
                color: lighten(#000, 0);
            }
            &__sponsor {
                width: 50%;
                @include media("<=phablet") {
                        width: 100%;
                }
            }
        }
    }
    &--eventpartners {
        .sponsorgroup__sponsor {
            width: 33%;

            &-logo {
                margin-top: $spacer-base * 1.25;
                width: 85%;
            }
        }
    }
    &--bronze {
        .sponsorgroup {
            &__title {
                color: rgba(#000, 0.6);
                &:before {
                    width: 480px - $spacer-base;
                }
            }
            &__sponsor {
                width: 50%;
                /*&--interbrand {
                    margin-top: $spacer-base * 1.25;
                }*/
            }
        }

        @include media(">phablet") {
            width: 50%;
            float: left;
            .sponsorgroup {
                &__title {
                    &:before {
                        width: 480px;
                    }
                }
                &__sponsor {
                    width: 50%;
                }
            }
        }
    }
    &--catering {
        .sponsorgroup {
            &__title {
                color: rgba(#000, 0.6);
            }
        }

        @include media(">phablet") {
            width: 50%;
            float: left;
            .sponsorgroup {
                &__title {
                    &:before {
                        width: 480px;
                    }
                }
                &__sponsor {
                    width: 50%;
                }
            }
        }
    }
    &--prizes {
        .sponsorgroup__sponsor {
            &--surface {
                width: 50%;
                .sponsorgroup__sponsor-logo {
                    margin-top: 0;
                }
                @include media("<=phablet") {
                    width: 100%;
                    padding-bottom: $spacer-base * 0.5;
                    .sponsorgroup__sponsor-logo {
                        width: 75%;
                    }
                }
            }

            &-logo {
                /*margin-top: $spacer-base * 1.25;*/
                margin-top: $spacer-base * 0.5;
                width: 85%;
            }
        }
    }

    &__sponsor {
        &--surface {
            .sponsorgroup {
                &__sponsor-logo {
                    width: 75%;
                    @include media("<tablet") {
                        width: 90%;
                    }
                }
            }
            margin-top: $spacer-base / 2;
        }
    }
}

/* Best in */
.bestin {
    padding-bottom: 3.5rem;
    &__list {
        font-size: 1.5rem;
        margin-left: $spacer-base;
        .text-inline-bg {
            font-size: 1.8rem;
            padding-left: 0.8rem;
        }
    }
}