$scheduleTimeWidth: 6rem;
$scheduleTimeSpace: 0.6rem;

@mixin scheduleTimeIndent() {
    padding-left: $scheduleTimeWidth + $scheduleTimeSpace;
    @include media("<tablet") {
        padding-left: $scheduleTimeWidth * 0.75 + $scheduleTimeSpace;
    }
}

@mixin highlightItem($highlightColor: rgba($clr-orange, 0.2)) {
    background-color: $highlightColor;
    padding-top: 0.1rem;
    box-shadow: 0.5rem 0 0 $highlightColor, -0.5rem 0 0 $highlightColor;
}


.schedule {
    &__day {
        display: none;

        &.is-active {
            display: block;
        }
    }

    &__list {
        @include reset-list-style();
    }

    &__item {
        @include clearfix();
        position: relative;
        margin-bottom: 1.2rem;

        &--break {
            margin-top: 1.8rem;
            margin-bottom: 1.8rem;
            .schedule__cell--title {
                .schedule__cell-text {
                    @include highlightItem();
                }
            }
        }
        &--allday {
            @include highlightItem(rgba($clr-orange, 0.8));
            color: white;
            .schedule__cell--location,
            .schedule__cell--time {
                color: #eee;
            }
            .schedule__cell-text {
                background: none;
                box-shadow: none;
            }
        }
        &--tba {
            .schedule__cell--title {
                .schedule__cell-text {
                    @include highlightItem(rgba($clr-grey-shade, 0.05));
                    color: $clr-grey-shade;
                }
            }

        }
        // &--speaker,
        // &--official {
        //     .schedule__cell--title {
        //         .schedule__cell-text {
        //             @include highlightItem(rgba($clr-grey-shade, 0.1));
        //         }
        //     }

        // }
    }

    &__cell {
        float: left;
        min-height: 2.4rem;

        &-text {
            @extend .text-inline-bg;
        }

        &--title {
            width: 100%;
            @include scheduleTimeIndent();
            padding-right: 24rem;
        }

        &--speaker {
            color: $clr-grey-shade;
            font-size: 16px;
            @include scheduleTimeIndent();
            min-height: 0;
        }

        &--time {
            font-size: 16px;
            position: absolute;
            top: 0;
            left: 0;
            width: $scheduleTimeWidth;
            color: $clr-grey-shade;
        }

        &--location {
            font-size: 16px;
            width: 140px;
            color: $clr-grey-shade;
            position: absolute;
            right: 0;
            top: 0;
        }
    }

    &__nav {
        @include scheduleTimeIndent();
        margin-bottom: 2.4rem;

        &-day {
            @extend .text-inline-bg;
            display: inline-block;
            margin-right: 1.2rem;
            font-size: 2rem;
            // color: $clr-orange;
            // border-color: $clr-orange;
            &:not(.is-active) {
                color: $clr-grey-shade;
                border-bottom: 0;
            }
        }
    }

    @include media ("<tablet") {
        &__cell {
            font-size: 1.4rem;
            &--title {
                padding-right: 0;
            }
            &--location {
                display: none;
            }
        }

        &__nav {
            &-day {
                font-size: 1.8rem;
                margin-right: 0.6rem;
            }
        }
    }
}

/* Info */
.info {
    padding-top: 1.5rem;
    &__list{
        font-size: 1.5rem;
        margin-left: $spacer-base;
        .text-inline-bg {
            font-size: 1.8rem;
            padding-left: 0.8rem;
        }
    }
}

/* Jury */
.jury {
    &__img {
        display: inline-block;
        width: 150px;
        vertical-align: top;
        padding-right: 2rem;
        padding-bottom: 2rem;
    }
    &__text {
        display: inline-block;
        max-width: 400px;
        vertical-align: top;
        margin-top: -0.5rem;
    }
}